import { createFileRoute } from "@tanstack/react-router";
import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { msalInstance } from "../../main";
import * as msal from "@azure/msal-browser";
import { loginRequest } from "../../authentication/config";

export const Route = createFileRoute("/analytics/store")({
  component: () => (
    <div className="w-full h-full relative">      
    <iframe
        allowFullScreen={true}
        className="w-full h-full border-none block"
        src="https://app.powerbi.com/reportEmbed?reportId=57acb805-78c3-48ee-8679-8c6235907084&autoAuth=true&ctid=4a34ac43-7589-41e9-b9d8-18a78e7675c1&hideToolbar=true&navContentPaneEnabled=false&pageName=ReportSection7b219594dc7e1833b795"
      ></iframe>
    </div>
  ),
});

async function getAccessToken() {
  try {
    const response = await msalInstance.acquireTokenSilent(loginRequest);
    return response.accessToken;
  } catch (error) {
    if (error instanceof msal.InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup(loginRequest);
      return response.accessToken;
    } else {
      throw error;
    }
  }
}

// Fetch embed URL from Power BI REST API
async function fetchEmbedUrl(accessToken: string) {
  const groupId = "2361c5dd-d254-4983-82e9-46d5ab0bc0ca"; // Replace with your workspace ID
  const reportId = "57acb805-78c3-48ee-8679-8c6235907084"; // Replace with your report ID
  const response = await fetch(
    `https://api.powerbi.com/v1.0/myorg/reports/${reportId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data = await response.json();
  return data.embedUrl;
}

const PowerBIReport = () => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      const token = await getAccessToken();
      setAccessToken(token);
      const embedUrl = await fetchEmbedUrl(token);
      setEmbedUrl(embedUrl);
    };
    init();
  }, []);

  if (!embedUrl || !accessToken) {
    return <div>Laddar...</div>;
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: "report",
        id: "57acb805-78c3-48ee-8679-8c6235907084",
        embedUrl: embedUrl,
        accessToken: accessToken,
        tokenType: models.TokenType.Aad, // Use 'Aad' for Azure AD access tokens
        settings: {
          panes: {
            filters: { visible: false },
            pageNavigation: { visible: true },
          },
        },
      }}
      eventHandlers={
        new Map([
          [
            "loaded",
            function () {
              console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered");
            },
          ],
          [
            "error",
            function (event) {
              console.log(event.detail);
            },
          ],
          ["visualClicked", () => console.log("visual clicked")],
          ["pageChanged", (event) => console.log(event)],
        ])
      }
      cssClassName="w-full h-full border-none block"
    />
  );
};
